
import { Component, Vue } from '@/lib/decorator';
import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import CustomerStatisticEntityModule from '@/store/customers/statistics/index';
import FilterInstant from '@/components/FilterInstant.vue';
import TableList from '@/components/data/TableList.vue';
import moment from 'moment';
import EChart from '@/components/EChart.vue';
import { getPlural } from '@/lib/Utils';
import statisticChartOptions, { init as initStatisticsOption } from './StatisticChartOptions';
import closedChartOptions, { init as initClosedOption } from './ClosedChartOptions';
import CustomerEntityModule from '@/store/customers/entity';
import CustomersModule from '@/store/customers';
import AppTable from '@/components/ui/table/Table.vue';
import { chartOptionsInterface, chartOptionsItem } from '@/views/analytics/byCustomers/interfaces';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { FilterModel } from '@/lib/layouts/page/filter.interface';
import { DatePickerEntityList } from '@/lib/layouts/page/filterDatePicker.interface';
import { TableInterface } from '@/lib/layouts/page/table.interface';
import { GuiLoader } from '@library/gigant_ui';

const scssVariables = {
  chartColorGreen: '#74D695',
  chartColorPurple: '#AD90D3',
  chartColorRed: '#E27F7F',
  chartColorDarkPurple: '#515184',
  chartColorBlue: '#5B93CC',
  chartColorDarkBlue: '#5870A0',
  chartColorYellow: '#E9C56A',
};

@Component({
  components: {
    GuiLoader,
    EChart,
    TableList,
    FilterInstant,
    TitleReturn,
    TabsNav,
    AppTable,
  },
  beforeMount() {
    this.customerId = this.$route.params.customerId as string;
  },
})
export default class CustomerStatistic extends Vue {
  customerId = '';
  initStatisticsOption = initStatisticsOption;
  initClosedOption = initClosedOption;
  statisticChartOptions = statisticChartOptions;
  closedChartOptions = closedChartOptions;

  statisticsAdditional = {
    successClosed: scssVariables.chartColorGreen,
    closedWithLate: scssVariables.chartColorPurple,
    needOpened: scssVariables.chartColorRed,
    fakeCall: scssVariables.chartColorYellow,
  };

  closedAdditional = {
    bySystem: scssVariables.chartColorDarkPurple,
    byEmployee: scssVariables.chartColorDarkBlue,
    byAdmin: scssVariables.chartColorBlue,
  };

  cols = [{ value: 'percent' }, { value: 'name', width: '100%' }, { value: 'count' }];

  get isTest(): boolean {
    return CustomerEntityModule.model.isTest;
  }

  get titlePage(): string {
    return CustomersModule.pageSettings.titleEdit;
  }

  get tabsNav(): TabsNavInterface {
    return CustomerEntityModule.tabsNav;
  }

  get currentTab(): string {
    return CustomerEntityModule.allTabs.statistics.id;
  }

  get model(): FilterModel {
    return CustomerStatisticEntityModule.filter.filterSettings.filterModel;
  }

  get startDate(): string {
    return (CustomerStatisticEntityModule.filter.filterSettings.filterModel.date.list as DatePickerEntityList)?.startDate
      .value;
  }

  get endDate(): string {
    return (CustomerStatisticEntityModule.filter.filterSettings.filterModel.date.list as DatePickerEntityList)?.endDate
      .value;
  }

  get period(): number {
    const start = moment(this.startDate, 'DD/MM/YY');
    const end = moment(this.endDate, 'DD/MM/YY');
    const duration = moment.duration(end.diff(start));

    return duration.asDays() + 1;
  }

  get declOfNumWordDay(): string {
    return getPlural(this.period, ['день', 'дня', 'дней']);
  }

  get markets(): TableInterface | {} {
    return CustomerStatisticEntityModule.markets;
  }

  get statisticsTotalCount(): number {
    return CustomerStatisticEntityModule.statisticsTotalCount;
  }

  get successClosed(): number {
    return CustomerStatisticEntityModule.successClosed;
  }

  get statistics(): Record<string, string | number>[] {
    return CustomerStatisticEntityModule.statistics;
  }

  get closed(): Record<string, string | number>[] {
    return CustomerStatisticEntityModule.closed;
  }

  get statisticsChart() {
    return this.mergeChartWithData({
      data: this.statistics,
      template: this.statisticChartOptions,
      additional: this.statisticsAdditional,
    });
  }

  get closedChart() {
    return this.mergeChartWithData({
      data: this.closed,
      template: this.closedChartOptions,
      additional: this.closedAdditional,
    });
  }

  mergeChartWithData(param: {
    data: Record<string, string | number>[];
    template: chartOptionsInterface;
    additional: { [index: string]: string };
  }) {
    let chartData = param.data
      .filter((el: Record<string, string | number>) => el.percent || el.percent === 0)
      .map((el: Record<string, string | number>) => {
        return {
          value: el.percent,
          name: el.name,
          label: {
            color: param.additional[el.id],
          },
          itemStyle: {
            color: param.additional[el.id],
          },
        };
      });

    param.template.series.forEach((el: Record<string, chartOptionsItem>) => {
      el.data = chartData as chartOptionsItem;
    });

    return param.template;
  }

  getCellAttrs() {
    let group: string;

    return function (props: { row: { group: string } }) {
      if (props.row.group === group) {
        return {};
      }

      group = props.row.group;

      return {
        class: ['cp-table-list-cell--divider', 'cp-table-list-cell--pt-8'],
      };
    };
  }

  returnBefore(): void {
    this.$router.push({ name: 'customers' });
  }

  async updateList(): Promise<void> {
    await CustomerStatisticEntityModule.filter.updateFilter();
    await CustomerStatisticEntityModule.getStatistics();
  }

  mounted(): void {
    CustomerEntityModule.initTabsItems();

    CustomerEntityModule.initTitle(this.customerId);
    CustomerStatisticEntityModule.init(this.customerId);
  }
}
