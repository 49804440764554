import { prepareStatistics } from '@/lib/Statistic';
import { assembleTitle } from '@/lib/util/tableUtils';

export function prepareCustomerStatistics(allStatistics: any) {
  return {
    ...prepareStatistics(allStatistics),

    marketsTable: {
      titles: [
        assembleTitle({
          id: 'name',
          name: 'Объект',
          visible: true,
        }),
        assembleTitle({
          id: 'percent',
          name: 'Закр. заказов, %',
          visible: true,
          align: 'right',
        }),
      ],
      rows: allStatistics['markets'] || [],
    },
  };
}

export const customerItems = [
  { id: 0, name: 'ООО "Гигант"', value: '0' },
  { id: 1, name: 'Клиент', value: '1' },
];
