import store from '@/store';
import PageBaseModule from '@/store/page';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { prepareCustomerStatistics } from '@/lib/Customer';
import { getClientStatistics } from '@/api/client';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import filterModel from './filter';
import CustomerStatisticsFilter from './filterEntity';
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import moment from 'moment';
import { inShiftListFirstElement } from '@/lib/Statistics';
import { CustomerStatisticsModel } from '@/interfaces/models/customer.interface';
import { TableInterface } from '@/lib/layouts/page/table.interface';

export const MODULE_NAME = 'customerStatistics';

interface CustomerStatisticsModelParsed {
  closed: Record<string, string | number>[];
  marketsTable: TableInterface;
  statistics: Record<string, string | number>[];
  statisticsTotalCount: number;
  successClosedCount: number;
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CustomerStatisticsModule extends PageBaseModule {
  customerId = '0';
  statistics: Record<string, string | number>[] = [];
  statisticsTotalCount = 0;
  successClosed = 0;
  closed: Record<string, string | number>[] = [];
  filter: Filter;
  markets: TableInterface | {} = {};

  constructor(module: CustomerStatisticsModule) {
    super(module);

    const filter = new filterModel();
    this.filter = getModule(CustomerStatisticsFilter);
    this.filter.setFilterName('customerStatisticsFilter');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
  }

  @Mutation
  SET_STATISTICS(params: CustomerStatisticsModelParsed) {
    this.successClosed = params.successClosedCount;
    this.closed = params.closed;
    this.statistics = params.statistics;
    this.statisticsTotalCount = params.statisticsTotalCount;
    this.markets = params.marketsTable;
  }

  @Mutation
  SET_CUSTOMER(customerId: string) {
    this.customerId = customerId;
  }

  @Action({ rawError: true })
  initDates() {
    const startDate = moment().add(-29, 'days').format('DD/MM/YY').split('/');

    const today = moment().format('DD/MM/YY').split('/');

    return {
      startDate: { day: startDate[0], month: startDate[1], year: startDate[2] },
      endDate: { day: today[0], month: today[1], year: today[2] },
    };
  }

  @Action({ rawError: true })
  async getStatistics() {
    try {
      const filter = this.filter.filterSettings.filter;

      if (filter.indexOf('dayStart') !== -1 || filter.indexOf('dayEnd') !== -1) {
        const result = await getClientStatistics(this.customerId, filter);

        this.updateData(result);
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  updateData(result: CustomerStatisticsModel) {
    const preparedData = prepareCustomerStatistics(result) as CustomerStatisticsModelParsed;

    this.SET_STATISTICS(preparedData);
  }

  @Action({ rawError: true })
  updateMaxPercent(result: { key: string; value: string }) {
    const tmp = { ...result };

    tmp.value = !tmp.value ? '100' : (tmp.value = tmp.value.replace(/\s|%/g, ''));

    this.filter.updateSearch(tmp);
  }

  @Action({ rawError: true })
  async init(customerId: string) {
    this.SET_CUSTOMER(customerId);

    this.filter.updateSelect({
      key: 'inShift',
      value: inShiftListFirstElement,
    });

    this.filter.updateSearch({
      key: 'maxPercent',
      value: '100',
    });

    await this.filter.init();

    await this.filter.updateFilter();

    this.getStatistics();
  }
}

export default getModule(CustomerStatisticsModule);
